@import 'src/assets/scss/colors';

.splashscreen-container {
  height: 100vh;
  background-color: $blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 60vw;
    height: auto;
    margin-bottom: 15vh;
    margin-top: -15vh;
  }

  h1 {
    color: white;
    font-weight: normal;
  }
}
