.camera-container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 0;

  .face-mask {
    opacity: 0.7;
    object-fit: contain;
    position: absolute;
  }

  .frame-mask {
    opacity: 0.5;
    width: 85%;
    height: 60%;
    position: absolute;
  }
}

.spinner {
  opacity: 0.8;
  position: absolute;
}
