.preview {
  margin: 0;
  padding: 0;

  width: 100vw;
}

.controls {
  position: absolute;
  bottom: 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  width: 100%;

  margin: 0;
  padding: 0;

  .controls-button {
    height: 60px;
    width: 60px;

    &:active {
      opacity: 0.5;
    }
  }

  .spinner {
    margin-top: -50px;
  }
}
