@import 'src/assets/scss/colors';

.topBar {
  background-color: $blue;
  height: 30px;
  padding: 20px;
  display: flex;
  align-items: center;

  .logoHead {
    margin-right: 10px;
    width: 30px;
    height: auto;
  }

  .logoText {
    width: 100px;
    height: auto;
  }
}
