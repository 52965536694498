@import "../../assets/scss/colors";

.example-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 70px);

  .row {
    display: flex;
    flex-direction: column;
    height: inherit;
    padding: 40px 0 0 0;

    p {
      text-align: center;
      color: $blue;
      margin: 0 20px 20px 20px;
    }

    .images {
      display: flex;
      justify-content: space-between;

      .do, .dont {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;

        .photo {
          width: inherit;
        }
      }

      .do {
        .photo {
          border: solid 2px green;
        }
      }

      .dont {
        .photo {
          border: solid 2px red;
        }
      }

      .icon {
        width: 30px;
        margin-top: 10px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    padding: 50px 0;

    button {
      margin: 0 20px;
    }
  }
}
