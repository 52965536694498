@import 'src/assets/scss/colors';

.container {
  height: 90vh;
  background-color: $blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;

  img {
    width: 60vw;
    height: auto;
  }

  form {
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      label {
        color: white;
        margin-bottom: 15px;
      }

      input {
        background-color: $blue;
        border: solid 1px white;
        border-radius: 10px;
        color: white;
        padding: 10px;
        font-size: 14px;

        &::placeholder {
          color: $gray;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: $gray;
        }

        &::-ms-input-placeholder {
          color: $gray;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
