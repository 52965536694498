@import "../../assets/scss/colors";

.logo {
  width: 15vw;
  margin: 20px 0;
}

.frame {
  width: 20vw;
  margin: 20px 0;
}

.text {
  width: 100%;
  margin-bottom: 20px;

  h1 {
    text-align: center;

    strong {
      font-size: unset;
    }
  }

  .section-title {
    font-weight: bold;
    margin-top: 20px;
    font-size: 14px;
  }

  p {
    font-size: 12px;
    text-align: left;
    margin-bottom: 5px;
  }

  strong {
    font-size: 12px;
    color: $blue;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    font-size: 14px;
  }
}
