@import 'colors';

* {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  color: $dark;
  font-size: 16px;
}

button {
  border-radius: 10px;
  padding: 10px 20px;
  width: min-content;
  white-space: nowrap;

  &:disabled {
    opacity: .6;
  }

  &.btn-white {
    background-color: white;
    color: $blue;
    border: solid 1px white;
  }

  &.btn-outline {
    background-color: white;
    color: $blue;
    border: solid 1px $blue;
  }

  &.btn-blue {
    background-color: $blue;
    color: white;
    border: solid 1px $blue;
  }
}

h1 {
  font-size: 18px;
  margin-bottom: 20px;
}
