@import 'src/assets/scss/colors';

.stepper {
  padding: 0;
  display: flex;
  width: 100%;

  .step {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--disabled);

    div {
      white-space: nowrap;
    }

    &.reached {
      color: black;
    }

    .badge {
      padding: 8px 10px;
      border-radius: 50px;
      float: left;
      font-size: 0.8rem;
      color: white;
      font-weight: bold;
      min-width: 10px;
      text-align: center;
      margin-bottom: 10px;
    }

    .blue {
      background-color: $blue;
    }

    .grey {
      background-color: $gray;
    }

    .label {
      font-size: 14px;
    }
  }

  .not-last:after {
    content: '';
    position: absolute;
    top: 25%;
    left: 80%;
    width: 34%;
    height: 1px;
  }

  :not(reached):after {
    background: $gray;
  }
}
